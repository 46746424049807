<template>
  <teleport to="#app">
    <div class="background" @click="toggleModal"></div>
    <dialog open>
      <main-form></main-form>
    </dialog>
  </teleport>
</template>

<script>
import Vuex from 'vuex'

export default {
  methods: {
    ...Vuex.mapMutations('modalForm', ['toggleModal']),
  },
}
</script>

<style scoped>
.background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
dialog {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 30px;
  z-index: 100;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
}

@media (max-width: 575px) {
  dialog {
    width: 90%;
  }
}
</style>
