<template>
  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
    <div id="box_ho" class="why-box">
      <i><img src="assets/images/star.png" alt="star" /></i>
      <h3><slot></slot></h3>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
img {
  max-width: 15%;
}

.why-box {
  height: 200px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .why-box {
    height: 180px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .why-box {
    height: 80px;
  }
}

@media (max-width: 575px) {
  .why-box {
    padding: 10px;
    height: fit-content;
  }

  img {
    max-width: 5%;
  }
}
</style>
