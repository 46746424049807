<template>
  <div id="opportunities" class="Services">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="titlepage">
            <h2>Возможности мобильного приложения</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="row cards">
          <mobile-card>Все объекты в телефоне</mobile-card>
          <mobile-card>Фиксация фото и видео повреждений</mobile-card>
          <mobile-card>Голосовые комментарии</mobile-card>
          <mobile-card>Постановка задач</mobile-card>
          <mobile-card>Уведомления</mobile-card>
          <mobile-card>Проведение сезонного осмотра</mobile-card>
          <mobile-card>Контроль выполнения работ</mobile-card>
        </div>
        <div class="row img">
          <img src="../../../public/assets/images/phone.png" alt="">
        </div>
      </div>
      <div class="row">
        <modal-button mode="btn btn-warning"> Узнать подробнее </modal-button>
      </div>
    </div>
  </div>
</template>

<script>
import MobileCard from '../UI/MobileCard.vue';

export default {
  components: {
    MobileCard,
  },
};
</script>

<style scoped>
 .cards {
  width: 70%;
 }
 .img {
  width: 30%;
 }
</style>
