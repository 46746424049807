<template>
  <div id="" class="hosting">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="titlepage">
            <h2>Автоматизировать работу легко!</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="web_hosting">
            <p>Занесение данные в сервис</p>
            <p>↓</p>
            <p>Установка мобильного приложения</p>
            <p>↓</p>
            <p>Ежедневное использование</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
