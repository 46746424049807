<template>
  <div id="contact" class="contact">
    <div class="container">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <main-form></main-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
form {
  position: relative;
  z-index: 10;
  margin-bottom: -58%;
}
</style>
