<template>
  <div class="container">
    <h1>ПОЛИТИКА ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h1>
    <div class="item">
      <h3>1. Общие положения</h3>
      <p>
        1.1 <span>ООО «Технологии развития» </span>(далее по тексту – Оператор)
        ставит соблюдение прав и свобод граждан одним из важнейших условий
        осуществления своей деятельности.
      </p>
      <p>
        1.2 Политика Оператора в отношении обработки персональных данных (далее
        по тексту — Политика) применяется ко всей информации, которую Оператор
        может получить о посетителях веб-сайта Персональные данные
        обрабатывается в соответствии с ФЗ «О персональных данных» № 152-ФЗ.
      </p>
    </div>
    <div class="item">
      <h3>2. Основные понятия, используемые в Политике:</h3>
      <p>
        2.1 Веб-сайт - совокупность графических и информационных материалов, а
        также программ для ЭВМ и баз данных, обеспечивающих их доступность в
        сети интернет по сетевому адресу <span>https://tr-stroy.com</span>;
      </p>
      <p>
        2.2 Пользователь – любой посетитель веб-сайта
        <span>https://tr-stroy.com</span>;
      </p>
      <p>
        2.3 Персональные данные – любая информация, относящаяся к Пользователю
        веб-сайта <span>https://tr-stroy.com</span>;
      </p>
      <p>
        2.4 Обработка персональных данных - любое действие с персональными
        данными, совершаемые с использованием ЭВМ, равно как и без их
        использования;
      </p>
      <p>
        2.5 Обезличивание персональных данных – действия, результатом которых
        является невозможность без использования дополнительной информации
        определить принадлежность персональных данных конкретному Пользователю
        или лицу;
      </p>
      <p>
        2.6 Распространение персональных данных – любые действия, результатом
        которых является раскрытие персональных данных неопределенному кругу
        лиц;
      </p>
      <p>
        2.7 Предоставление персональных данных – любые действия, результатом
        которых является раскрытие персональных данных определенному кругу лиц;
      </p>
      <p>
        2.8 Уничтожение персональных данных – любые действия, результатом
        которых является безвозвратное уничтожение персональных на ЭВМ или любых
        других носителях.
      </p>
    </div>
    <div class="item">
      <h3>3. Оператор может обрабатывать следующие персональные данные:</h3>
      <p>
        <span>
          Проверьте, соответствует ли список ниже тем данным, которые собираете
          вы. Если нет, добавьте/удалите данные
        </span>
      </p>
      <p><span>3.1 Адрес электронной почты Пользователя</span></p>
      <p><span>3.2 Номер телефона Пользователя</span></p>
      <p><span>3.3. Фамилию, имя, отчество Пользователя</span></p>
      <p><span>3.4. Наименование организации Пользователя</span></p>
      <p>
        3.5. Также на сайте происходит сбор и обработка обезличенных данных о
        посетителях (в т.ч. файлов «cookie») с помощью сервисов
        интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).
      </p>
    </div>
    <div class="item">
      <h3>4. Цели обработки персональных данных</h3>
      <p>
        4.1 Цель обработки адреса электронной почты, номера телефона, фамилии,
        имени,наименование организации, отчества Пользователя -
        <span>уточнение деталей заказа Пользователя</span>.Также Оператор имеет
        право направлять Пользователю уведомления о новых продуктах и услугах,
        специальных предложениях и различных событиях. Пользователь всегда может
        отказаться от получения информационных сообщений, направив Оператору
        письмо на адрес <span>https://tr-stroy.com</span>
      </p>
      <p>
        4.2 Обезличенные данные Пользователей, собираемые с помощью сервисов
        интернет-статистики, служат для сбора информации о действиях
        Пользователей на сайте, улучшения качества сайта и его содержания.
      </p>
    </div>
    <div class="item">
      <h3>5. Правовые основания обработки персональных данных</h3>
      <p>
        5.1 Оператор обрабатывает персональные данные Пользователя только в
        случае их отправки Пользователем через формы, расположенные на сайте
        <span>https://tr-stroy.com</span>. Отправляя свои персональные данные
        Оператору, Пользователь выражает свое согласие с данной Политикой.
      </p>
      <p>
        5.2 Оператор обрабатывает обезличенные данные о Пользователе в случае,
        если это разрешено в настройках браузера Пользователя (включено
        сохранение файлов «cookie» и использование технологии JavaScript).
      </p>
    </div>
    <div class="item">
      <h3>
        6. Порядок сбора, хранения, передачи и других видов обработки
        персональных данных
      </h3>
      <p>
        6.1 Оператор обеспечивает сохранность персональных данных и принимает
        все возможные меры, исключающие доступ к персональным данным
        неуполномоченных лиц.
      </p>
      <p>
        6.2 Персональные данные Пользователя никогда, ни при каких условиях не
        будут переданы третьим лицам, за исключением случаев, связанных с
        исполнением действующего законодательства.
      </p>
      <p>
        6.3. В случае выявления неточностей в персональных данных, Пользователь
        может актуализировать их, направив Оператору уведомление с помощью
        электронной почты на электронный адрес Оператора
        <span>https://tr-stroy.com</span> с пометкой «Актуализация персональных
        данных»
      </p>
      <p>
        6.4 Срок обработки персональных данных является неограниченным.
        Пользователь может в любой момент отозвать свое согласие на обработку
        персональных данных, направив Оператору уведомление с помощью
        электронной почты на электронный адрес Оператора
        <span>https://tr-stroy.com</span>, с пометкой «Отзыв согласия на
        обработку персональных данных».
      </p>
    </div>
    <div class="item">
      <h3>7. Заключительные положения</h3>
      <p>
        7.1. Пользователь может получить любые разъяснения по интересующим
        вопросам, касающимся обработки его персональных данных, обратившись к
        Оператору с помощью электронной почты <span>https://tr-stroy.com</span>.
      </p>
      <p>
        7.2. В данном документе будут отражены любые изменения политики
        обработки персональных данных Оператором. В случае существенных
        изменений Пользователю может быть выслана информация на указанный им
        электронный адрес.
      </p>
    </div>
  </div>
</template>

<style scoped>
.container {
  padding: 40px 0;
}
span {
  font-weight: bold;
}
.item {
  margin-bottom: 10px;
}
</style>
