<template>
  <div id="result" class="Services">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="titlepage">
            <h2>В результате вы получите</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <img src="../../../public/assets/images/happy.png" alt="" />
      </div>
      <div class="row">
        <result-card>Сезонные осмотры в «один клик»</result-card>
        <result-card>Хранилище всей документации</result-card>
        <result-card>Планирование бюджета</result-card>
        <result-card>Постановка задач</result-card>
        <result-card>Управление задачами и их выполнением</result-card>
        <result-card>Хронологию работ по всем объектам</result-card>
      </div>
      <div class="row">
        <modal-button mode="btn btn-warning"> Оставить заявку </modal-button>
      </div>
    </div>
  </div>
</template>

<script>
import ResultCard from '../UI/ResultCard.vue';

export default {
  components: {
    ResultCard,
  },
};
</script>

<style scoped>
img {
  width: 30%;
}
</style>
