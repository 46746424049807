<template>
  <the-header></the-header>
  <the-banner></the-banner>
  <the-service></the-service>
  <the-goal></the-goal>
  <how-works></how-works>
  <the-mobile></the-mobile>
  <the-place></the-place>
  <the-result></the-result>
  <the-easy></the-easy>
  <the-form></the-form>
  <the-footer></the-footer>
</template>
