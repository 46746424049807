<template>
  <section class="banner_main">
    <div class="container">
      <div class="row d_flex">
        <div class="col-md-5">
          <div class="text-bg">
            <h1>ЭКСПЛУАТАЦИЯ ЗДАНИЙ с помощью современных IT-технологий</h1>
            <span>Сервис «PROосмотр»</span>
            <p>
              предназначен для качественной эксплуатации зданий и сооружений
            </p>
            <modal-button mode="btn btn-light"> Оставить заявку </modal-button>
          </div>
        </div>
        <div class="col-md-7">
          <div class="text-img">
            <figure><img src="assets/images/img.png" /></figure>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
}
</script>
