import { createRouter, createWebHistory } from "vue-router";
import MainPage from '../components/pages/MainPage.vue'
import PrivacyPage from '../components/pages/PrivacyPage.vue'
import TermPage from '../components/pages/TermPage.vue'

const routes = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'MainPage',
      component: MainPage,
    },
    {
      path: "/privacy",
      name: "PrivacyPage",
      component: PrivacyPage,
    },
    {
      path: "/term",
      name: "TermPage",
      component: TermPage,
    },
  ]
});

// const router = createRouter({
//   history: createWebHistory('/'),
//   routes,
// });

export default routes;