<template>
  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
    <div class="Services-box">
      <h3><slot></slot></h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
}
</script>

<style scoped>
.Services-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 100px;
}
.Services-box h3 {
  padding: 0;
}

@media (min-width: 576px) and (max-width: 767px) {
  .Services-box {
    height: 40px;
  }
  .Services-box h3 {
    font-size: 18px;
    line-height: 18px;
  }
}

@media (max-width: 575px) {
  .Services-box {
    padding: 10px;
    height: fit-content;
  }
}
</style>
