<template>
  <footer>
    <div id="footer" class="footer">
      <div class="container">
        <div class="row align-items-start">
          <div class="col info">
            <p>ООО "Технологии развития"</p>
            <p>614000, г. Пермь, ул. Советская, д. 54, оф. 304</p>
            <p>ИНН/КПП 5902057830/590201001</p>
            <p>ОГРН 1205900009853</p>
          </div>
          <div class="col contacts">
              <p>
                <a class="cont-link" href="tel:+79127808153">
                  +7 (912) 780-81-53
                </a>
              </p>
              <p>
                <a class="cont-link" href="mailto:Ba@tr-stroy.com">
                  Ba@tr-stroy.com
                </a>
              </p>
            <p class="privacy">
              <router-link to="/privacy" target="_blank">
                Политика конфиденциальности
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {}
</script>
