<template>
  <div id="why" class="why">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="titlepage">
            <h2>Рабочее место позволяет</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <img src="../../../public/assets/images/laptop.png" alt="" />
      </div>
      <div class="row">
        <place-card>Создавать акты сезонных осмотров в «один клик»</place-card>
        <place-card>Видеть выполнения задач по каждому сотруднику</place-card>
        <place-card>Хранить всю документацию по объекту</place-card>
        <place-card>Планировать бюджет и отслеживать его исполнение</place-card>
        <place-card>Ставить задачи по объектам</place-card>
        <place-card>Планировать проведение сезонных осмотров</place-card>
        <place-card>Создавать базу типовых повреждений</place-card>
        <place-card>Планировать ремонты</place-card>
      </div>
    </div>
  </div>
</template>

<script>
import PlaceCard from '../UI/PlaceCard.vue';

export default {
  components: {
    PlaceCard,
  },
};
</script>

<style scoped>
img {
  width: 30%;
}
</style>
