import Http from "../utils/http";
import Config from "../../config";

const state = {
  isModalOpened: false,
  formData: {
    name: '',
    email: '',
    phone: '',
    message: '',
    isChecked: false
  },
  isSent: false,
  isError: false
};

const getters = {
  isModalOpened: (state) => state.isModalOpened,
  formData: (state) => state.formData,
  isSent: (state) => state.isSent,
  isError: (state) => state.isError
};

const mutations = {
  toggleModal(state) {
    state.isModalOpened = !state.isModalOpened;
  },
  setSend(state, value) {
    state.isSent = value;
  },
  setError(state, value) {
    state.isError = value;
  },
  eraseForm(state) {
    state.formData = {
      name: '',
      email: '',
      phone: '',
      message: '',
      isChecked: false
    };
  },
};

const actions = {
  send: ({ commit }, form) => {
    if (!form.name || !form.email || !form.phone || !form.isChecked) {
      // alert('Убедитесь, что вы заполнили все поля со звездочкой');
      commit('setError', true);
      commit('setSend', false);
      return;
    }

    return new Promise((resolve) => {
      Http.post(Config.apiUrl + '/integration/send_application', form)
        .then(response => {
          console.log(response);
          commit("setSend", true);
          commit("eraseForm");
          resolve(response);
        })
        .catch(err => {
          console.log(err);
          commit("setSend", false);
        })
    });
  }
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
};