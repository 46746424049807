<template>
  <div id="" class="hosting why">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="titlepage">
            <h2>Наша цель</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="web_hosting">
            <!-- <figure>
              <img src="assets/images/web.jpg" alt="#" />
            </figure> -->
            <p>
              Мы создали сервис «PROосмотр», чтобы улучшить эксплуатацию зданий
              за счет внедрения современных технологий. Это отличная возможность
              повысить эффективность своей службы!
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <goal-card>
          <h3>ВРЕМЯ</h3>
          <p>Сокращение времени на выполнение сезонных осмотров на 30%</p>
        </goal-card>
        <goal-card>
          <h3>ДЕНЬГИ</h3>
          <p>Управление бюджетом и планирование затрат</p>
        </goal-card>
        <goal-card>
          <h3>УПРАВЛЕНИЕ</h3>
          <p>Постановка и контроль выполнения задач</p>
        </goal-card>
        <goal-card>
          <h3>ХРАНЕНИЕ ДАННЫХ</h3>
          <p>
            Все данные по объекту в одном окне (информация о ремонтах,
            техническая документация и др)
          </p>
        </goal-card>
      </div>
    </div>
  </div>
</template>

<script>
import GoalCard from '../UI/GoalCard.vue';

export default {
  components: {
    GoalCard,
  },
};
</script>

<style scoped>
.why {
  margin-top: 0;
  background: #0c0f38;
}
.why p,
.why h3,
h2 {
  color: #fff;
}
</style>
