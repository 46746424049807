<template>
  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
    <div class="Services-box">
      <i><img :src="serviceImg" alt="#" /></i>
      <p>
        <slot></slot>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    serviceImg: String,
    title: String,
  },
}
</script>
