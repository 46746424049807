<template>
  <header>
    <div class="header">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col logo_section">
            <div class="full">
              <div class="center-desk">
                <the-logo></the-logo>
              </div>
            </div>
          </div>
          <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9">
            <nav class="navigation navbar navbar-expand-md navbar-dark">
              <button
                class="navbar-toggler"
                type="button"
                @click="toggleBurgerMenu"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div
                class="collapse navbar-collapse"
                id="navbarsExample04"
                :class="visionClasses"
              >
                <ul class="navbar-nav mr-auto" @click="toggleBurgerMenu">
                  <li class="nav-item">
                    <a class="nav-link" href="#when">Когда внедрять?</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#opportunities">Возможности</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#result">Результат</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#footer">Контакты</a>
                  </li>
                </ul>
                <modal-button mode="btn btn-light" @click="toggleBurgerMenu">
                  Оставить заявку
                </modal-button>
                <modal-card v-if="isModalOpened"></modal-card>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Vuex from 'vuex'

export default {
  data() {
    return {
      isNavigationShown: '',
    }
  },
  computed: {
    ...Vuex.mapGetters('modalForm', ['isModalOpened']),
    visionClasses() {
      return {
        visible: this.isNavigationShown,
        hidden: !this.isNavigationShown,
      }
    },
  },
  watch: {},
  methods: {
    toggleBurgerMenu() {
      this.isNavigationShown = !this.isNavigationShown
    },
  },
}
</script>

<style scoped>
.background {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.navbar-nav {
  align-items: center;
}
.navbar-toggler {
  position: relative;
  z-index: 999;
}
.visible {
  display: flex;
}
.hidden {
  display: none;
}
@media (max-width: 767px) {
  .navbar-collapse {
    position: absolute;
    z-index: 999;
  }
}
</style>
