<template>
  <form @submit.prevent="send(formData)">
    <div class="form_container">
      <error-message v-if="isError"></error-message>
      <div class="form__input">
        <input
          placeholder="Имя*"
          type="text"
          name="Name"
          v-model="formData.name"
        />
      </div>
      <div class="form__input">
        <input
          placeholder="Email*"
          type="text"
          name="Email"
          v-model="formData.email"
        />
      </div>
      <div class="form__input">
        <input
          placeholder="Телефон*"
          type="text"
          name="Phone"
          v-model="formData.phone"
        />
      </div>
      <div class="form__input">
        <textarea
          placeholder="Комментарий"
          type="text"
          name="Message"
          v-model="formData.message"
        ></textarea>
      </div>
      <div class="form__checkbox">
        <input
          type="checkbox"
          name="Checkbox"
          id="agreeTerm"
          v-model="formData.isChecked"
        />
        <label class="form__label" for="agreeTerm">
          Отправляя заявку, вы даете согласие на
          <router-link to="/term" target="_blank">
            обработку своих персональных данных
          </router-link>
          *
        </label>
      </div>
      <div class="form__btn">
        <button class="send">Отправить</button>
      </div>
    </div>
  </form>
</template>

<script>
import ErrorMessage from './ErrorMessage.vue'
import Vuex from 'vuex'

export default {
  components: {
    ErrorMessage,
  },
  computed: {
    ...Vuex.mapGetters('modalForm', ['formData']),
    ...Vuex.mapGetters('modalForm', ['isError']),
  },
  methods: {
    ...Vuex.mapActions({
      send: 'modalForm/send',
    }),
  },
}
</script>

<style scoped>
.form_container {
  padding: 55px 55px;
  background: #0c0f38;
  border-radius: 30px;
}
.form__input {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
}
.form__input input,
.form__input textarea {
  width: 430px;
  padding: 0px 19px;
  border-radius: 15px;
  color: #999999;
  background: #fff;
  border: #fff solid 1px;
}
.form__input input {
  margin-bottom: 20px;
  height: 52px;
}
.form__input textarea {
  margin-bottom: 25px;
  padding-top: 38px;
}
.form__checkbox {
  display: flex;
  align-items: flex-start;
  width: 430px;
  color: #fff;
}
.form__checkbox input {
  margin-top: 5px;
  margin-right: 8px;
}
.form__label a {
  color: #fdd430;
}
.send {
  font-size: 16px;
  transition: ease-in all 0.5s;
  background-color: #fff;
  color: #ef4259;
  padding: 12px 70px;
  margin: 0 auto;
  display: block;
  border-radius: 30px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .form__input input,
  .form__input textarea,
  .form__checkbox {
    max-width: 340px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .form__input input,
  .form__input textarea,
  .form__checkbox {
    max-width: 220px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .form__input input,
  .form__input textarea,
  .form__checkbox {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .form__input input,
  .form__input textarea,
  .form__checkbox {
    width: 100%;
  }
  .send {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
