<template>
  <div id="when" class="Services">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="titlepage">
            <h2>Когда нужно внедрять?</h2>
          </div>
        </div>
      </div>
      <div class="row row-img">
        <img src="../../../public/assets/images/when.jpg" alt="" />
      </div>
      <div class="row">
        <service-card serviceImg="assets/images/service/warn.png">
          Сезонные осмотры выполняются «в ручную», создаются акты в Word
        </service-card>
        <service-card serviceImg="assets/images/service/warn.png">
          Отсутствует единое хранилище со всей технической, проектной,
          исполнительной документацией по объектам
        </service-card>
        <service-card serviceImg="assets/images/service/warn.png">
          Планирование и выполнение ремонтов осуществляется в excel
        </service-card>
        <service-card serviceImg="assets/images/service/warn.png">
          Сложности с определением приоритетов ремонта из-за ограниченного
          бюджета
        </service-card>
        <service-card serviceImg="assets/images/service/warn.png">
          Отсутствует система для постановки задач и контроля их выполнения
        </service-card>
      </div>
      <!-- <div class="row">
        <img src="../../../public/assets/images/when.jpg" alt="" />
      </div> -->
    </div>
  </div>
</template>

<script>
import ServiceCard from '../UI/ServiceCard.vue';

export default {
  components: {
    ServiceCard,
  },
};
</script>

<style scoped>
.Services {
  margin-top: 0;
  background: #fff;
}

.Services .titlepage h2 {
  color: #090808;
  padding-bottom: 20px;
}

.row-img {
  margin-bottom: 10px;
}

img {
  align-self: center;
  width: 30%;
}
@media (min-width: 768px) and (max-width: 991px) {
  img {
    width: 50%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  img {
    width: 50%;
  }
}

@media (max-width: 575px) {
  img {
    width: 50%;
  }
}
</style>
