<template>
  <div id="" class="hosting">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="titlepage">
            <h2>Как работает?</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="web_hosting">
            <p>
              Сервис состоит из мобильного приложения для фиксации повреждений и
              постановки задач, а также из рабочего места инженера
            </p>
            <figure>
              <img src="assets/images/how-works.jpeg" alt="#" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
