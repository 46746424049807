<template>
  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
    <div class="why-box">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.why-box {
  padding: 10px;
  height: 220px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .why-box {
    height: 180px;
  }
  .why-box h3 {
    font-size: 18px;
  }
  .why-box p {
    font-size: 14px;
    line-height: 18px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .why-box {
    height: 120px;
  }
}

@media (max-width: 575px) {
  .why-box {
    padding: 10px;
    height: fit-content;
  }

  img {
    max-width: 5%;
  }
}
</style>
