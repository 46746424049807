<template>
  <div class="container">
    <h1>Согласие на обработку персональных данных</h1>
    <div class="item">
      <p>
        Настоящим в соответствии с Федеральным законом № 152-ФЗ «О персональных
        данных» от 27.07.2006 года свободно, своей волей и в своем интересе
        выражаю свое безусловное согласие на обработку моих персональных данных
        Общество с Ограниченной Ответственностью «Технологии Развития» ( ИНН
        5902057830), зарегистрированным в соответствии с законодательством РФ по
        адресу:
      </p>
      <p>
        614014, Пермский край, город Пермь, ул. Советская 54 офис 304 (далее по
        тексту - Оператор).
      </p>
      <p>
        Персональные данные - любая информация, относящаяся к определенному или
        определяемому на основании такой информации физическому лицу.
      </p>
      <p>
        Настоящее Согласие выдано мною на обработку следующих персональных
        данных:
      </p>
      <p>- Ваше имя;</p>
      <p>- Телефон;</p>
      <p>- IP-адрес;</p>
      <p>- e-mail адрес.</p>
    </div>
    <div class="item">
      <p>
        Согласие дано Оператору для совершения следующих действий с моими
        персональными данными с использованием средств автоматизации и/или без
        использования таких средств: сбор, систематизация, накопление, хранение,
        уточнение (обновление, изменение), использование, обезличивание, а также
        осуществление любых иных действий, предусмотренных действующим
        законодательством РФ как неавтоматизированными, так и
        автоматизированными способами.
      </p>
      <p>
        Данное согласие дается Оператору для обработки моих персональных данных
        в следующих целях:
      </p>
      <p>- предоставление мне услуг/работ;</p>
      <p>
        - направление в мой адрес уведомлений, касающихся предоставляемых
        услуг/работ;
      </p>
      <p>- подготовка и направление ответов на мои запросы;</p>
      <p>
        - направление в мой адрес информации, в том числе рекламной, о
        мероприятиях/товарах/услугах/работах Оператора.
      </p>
    </div>
    <div class="item">
      <p>
        Настоящее согласие действует до момента его отзыва путем направления
        соответствующего уведомления на электронный адрес tr@tr-stroy.com В
        случае отзыва мною согласия на обработку персональных данных Оператор
        вправе продолжить обработку персональных данных без моего согласия при
        наличии оснований, указанных в пунктах 2 – 11 части 1 статьи 6, части 2
        статьи 10 и части 2 статьи 11 Федерального закона №152-ФЗ «О
        персональных данных» от 27.07.2006 г.
      </p>
    </div>
  </div>
</template>

<style scoped>
.container {
  padding: 40px 0;
}
span {
  font-weight: bold;
}
.item {
  margin-bottom: 10px;
}
</style>
