<template>
  <button type="button" :class="mode" @click="toggleModal">
    <slot></slot>
  </button>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  props: ['mode'],
  methods: {
    ...mapMutations('modalForm', ['toggleModal']),
  },
}
</script>
