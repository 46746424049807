import { createApp } from 'vue';
import App from './App.vue';
import store from './store/index';
import routes from "./routers/routers";

import MainPage from './components/pages/MainPage.vue'
import PrivacyPage from './components/pages/PrivacyPage.vue'
import TermPage from './components/pages/TermPage.vue'

import TheHeader from './components/layout/TheHeader.vue'
import TheBanner from './components/layout/TheBanner.vue'
import TheGoal from './components/layout/TheGoal.vue'
import TheService from './components/layout/TheService.vue'
import HowWorks from './components/layout/HowWorks.vue'
import TheMobile from './components/layout/TheMobile.vue'
import ThePlace from './components/layout/ThePlace.vue'
import TheResult from './components/layout/TheResult.vue'
import TheEasy from './components/layout/TheEasy.vue'
import TheForm from './components/layout/TheForm.vue'
import TheFooter from './components/layout/TheFooter.vue'

import TheLogo from './components/UI/TheLogo.vue'
import MainForm from './components/UI/MainForm.vue'
import ModalButton from './components/UI/ModalButton.vue'
import ModalCard from './components/UI/ModalCard.vue'

const app = createApp(App);

app.component('the-header', TheHeader);
app.component('the-banner', TheBanner);
app.component('the-goal', TheGoal);
app.component('the-service', TheService);
app.component('how-works', HowWorks);
app.component('the-mobile', TheMobile);
app.component('the-place', ThePlace);
app.component('the-result', TheResult);
app.component('the-easy', TheEasy);
app.component('the-form', TheForm);
app.component('the-footer', TheFooter);

app.component('the-logo', TheLogo);
app.component('main-form', MainForm);
app.component('modal-button', ModalButton);
app.component('modal-card', ModalCard);

app.component('main-page', MainPage);
app.component('the-privacy', PrivacyPage);
app.component('the-term', TermPage);

app.use(routes);
app.use(store);
app.mount('#app');
