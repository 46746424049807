import { createStore } from 'vuex';

import modalForm from './modalForm';
import burgerMenu from './burgerMenu';

const store = createStore({
  modules: {
    modalForm,
    burgerMenu
  },
});

export default store;